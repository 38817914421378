<template>
    <div class="Head-box">

        <div class="toptips" :style="{backgroundImage:'url('+require('../assets/img/z1.png')+')'}">
            <div class="container">
                <div class="t1">北京睿易博达科技有限公司</div>
                <div class="t2">               	
                    <div class="text">
                        气象服务-气象数据-气象大数据-全球气候-气象风险评估-历史气象数据
                    </div>
                </div>
            </div>
        </div>
        <div class="top" >
            <div class="logo" >
                <img class="img" src="~assets/img/bgg.png" alt="标题头">
            </div>
            <div class="list">
                <div class="nav-router" >
                    <div class="nav-item">
                        <router-link  active-class="active" :to="linkeTo">数据服务</router-link>
                    </div>
                    <div class="nav-item">
                        <router-link active-class="active" to="/Project">合作策划</router-link>
                    </div>
                    <div class="nav-item">
                        <router-link active-class="active" to="/ItemService">气象服务</router-link>
                    </div>
                    <div class="nav-item">
                        <router-link active-class="active" to="/TecService">技术服务</router-link>
                    </div>
                    <div class="nav-item">
                        <router-link active-class="active" to="/About">公司概况</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                linkeTo: "/",
            }
        },
        beforeMount() {
            this.activeClass();
        },
        methods: {
            activeClass() {
                var url = this.$route.path;
                this.linkeTo = url;
                if (url == "/Weather" || url == "/") {
                }  else if (url == "/Project" || url == "/ItemService" || url == "/TecService" || url == "/About" ) {
                    this.linkeTo = "/Weather";
                } else {
                    this.linkeTo = "/Weather";
                }
            }
        },
        watch: {
            $route() {
                this.activeClass();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .toptips {
        height: 42px;
        line-height: 42px;
        font-family: "微软雅黑";
        .container {
            position: relative;
            height: 42px;
        }
       .t1 {
            font-size: 16px;
            color: #FFFFFF;
            position: absolute;
            left: 61px;
            top: 0;
        }
        .t2 {
            font-size: 16px;
            color: #ffe100;
            position: absolute;
            right: 0;
            top: 0;
            
            .text {
                margin-right: 79px;
            }
        }
        .t3 {
            font-size: 14px;
            color: #ffe100;
            position: absolute;
            right: 0;
            top: 0;
            
            .text {
                margin-right: 545px;
            }
        }

    }
    .top{
        height: 80px;
        line-height: 80px;
        position: relative;
        z-index: 100;
        background: #ffffff;
        border-bottom: 2px solid #d1cfdc;
       .logo {
            width: 160px;
            height: 80px;
            position: absolute;
            left: 48px;
            top: 5px;
           img{
               display: block;
               height: 85%;
               background-position: left center;
               background-repeat: no-repeat;
           }
        }
        .list {
            position: absolute;
            right: 64px;
            top: 0;
        }
        .nav-router {
            margin: 0px;
            padding: 0px;
            display: flex;
            .nav-item {
                margin: 0 0;
                font-size: 18px;
                font-family: "微软雅黑";
                text-align: center;
                font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
                a {
                    color:#383637;;
                    display: block;
                    text-decoration: none;
                       padding: 0 20px;
                }
                :hover{
                    background-color: #ffe100;
                };
            }

            .active {
                background-color: #ffe100;
            }
        }
    }


</style>
